import Layout from "../layout/Layout";
import { motion } from "framer-motion";
import { t } from "i18next";
import "./experiencesStyles.css";
import { experiencesData } from "../utils/experiencesData";
const Experiences = () => {
  return (
    <Layout>
      <motion.div
        initial={{ x: "-100vw" }}
        animate={{ x: 0 }}
        exit={{ x: "-100vw" }}
      >
        <div className="container py-2rem">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              {experiencesData.map((job) => (
                <div className="project" key={job.key}>
                  <h5>
                    {job.position} - @{job.firm}
                  </h5>
                  <p>
                    {t(job.startDate)} - {t(job.endDate)}
                  </p>
                  {job.projects.map((item, index) => (
                    <details key={index}>
                      <summary>{item.name}</summary>
                      <p className="detail-text">
                        <span className="detail-title">
                          {t("experience-projects-subtitle-1")}:
                        </span>
                        {t(item.description)}
                      </p>
                      <p className="detail-text">
                        <span className="detail-title">
                          {t("experience-projects-subtitle-2")}:
                        </span>
                        {t(item.techStack.join(", "))}
                      </p>
                    </details>
                  ))}
                </div>
              ))}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Experiences;
