import React from "react";
import { NavLink } from "react-router-dom";
import Layout from "../layout/Layout";
import { t } from "i18next";
const NotFound = () => {
  return (
    <Layout>
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ minHeight: "300px" }}
      >
        <div className="thank-you">
          <h1 className="fs-1 text-normal">{t("not-found")}</h1>
          <NavLink to="/" className="fs-5 mt-4 d-block mx-auto text-center">
            <i className="las la-undo mx-2"></i>
            {t("return")}
          </NavLink>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
