import React from "react";
import ProjectLink from "../projectLink/ProjectLink";
import { t } from "i18next";
const ProjectCard = ({ title, desc, imgSrc, link }) => {
  return (
    <div className="card h-100">
      <img src={imgSrc} className="card-img-top" alt="Project 1" style={{borderRadius:0, height:"120px", objectFit:"cover"}} />
      <div className="card-body">
        <h5 className="card-title">{t(title)}</h5>
        <p className="card-text">{t(desc)}</p>
      </div>
      <div className="card-footer py-0">
        <ProjectLink link={link} description="" />
      </div>
    </div>
  );
};

export default ProjectCard;
