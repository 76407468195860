import { motion } from "framer-motion";
import { Trans } from "react-i18next";
import { useToaster } from "../hooks/useToaster";
import Layout from "../layout/Layout";
import ContactForm from "../components/contactForm/ContactForm";

const Contact = () => {
  const { toasterContainer } = useToaster();
  return (
    <Layout>
      {toasterContainer}
      <motion.div
        initial={{ x: "100vw" }}
        animate={{ x: 0 }}
        exit={{ x: "100vw" }}
      >
        <div className="container py-2rem">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="row g-3">
                <p className="text-normal">
                  <Trans
                    components={{
                      a: (
                        <a
                          className="email-link"
                          aria-label="E-Mail Link"
                          href="mailto:onuralpozkan01@gmail.com"
                        >
                          T
                        </a>
                      ),
                    }}
                  >
                    contact-via-link
                  </Trans>
                </p>
              </div>
              <ContactForm />
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Contact;
