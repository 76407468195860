import Banner from "../components/banner/Banner";
import Navbar from "../components/navbar/Navbar";
import "./layoutStyle.css";
import Footer from "../components/footer/Footer";
const Layout = ({ children }) => {
  return (
    <div className="container-fluid main-container">
      <Navbar />
      <Banner />
      <div className="content py-3">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;