import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import About from "./pages/About";
import { AnimatePresence } from "framer-motion";
import Experiences from "./pages/Experiences";
import Contact from "./pages/Contact";
import { withTranslation } from "react-i18next";
import ThankYou from "./pages/ThankYou";
import ProjectsModal from "./components/projectsModal/ProjectsModal";
import { Suspense } from "react";
import Spinner from "./components/spinner/Spinner";
import NotFound from "./pages/NotFound";
import Layout from "./layout/Layout";
function App() {
  const location = useLocation();
  const Home = React.lazy(() => import("./pages/Home"));
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <Layout>
                  <Spinner />
                </Layout>
              }
            >
              <Home />
            </Suspense>
          }
        ></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/experiences" element={<Experiences />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/thank-you" element={<ThankYou />}></Route>
        <Route path="/not-found" element={<NotFound />}></Route>
        <Route
          path="/thank-you"
          element={<Navigate replace to="/thank-you" />}
        ></Route>
        <Route path="*" element={<Navigate replace to="/not-found" />}></Route>
      </Routes>
      <ProjectsModal />
    </AnimatePresence>
  );
}

export default withTranslation()(App);
