import { t } from "i18next";
import { NavLink } from "react-router-dom";
const ThankYou = () => {
  return (
    <div
      className="container d-flex align-items-center justify-content-center"
      style={{ minHeight: "300px" }}
    >
      <div className="thank-you">
        <h1 className="fs-1 text-italic">{t("thanks")}</h1>
        <NavLink to="/" className="fs-5 mt-4 d-block mx-auto text-end">
          <i className="las la-undo mx-2"></i>
          {t("return")}
        </NavLink>
      </div>
    </div>
  );
};

export default ThankYou;
