import React, { useContext } from "react";
import { ModalContext } from "../../context/modalContext";
import "./modalStyles.css";
import { motion } from "framer-motion";
import ProjectCard from "../projectCard/ProjectCard";
import { githubLinks } from "../../utils/links";
import { t } from "i18next";
const ProjectsModal = () => {
  const { isModalOpen, toggleModal } = useContext(ModalContext);
  const renderProjects = githubLinks.map((project) => (
    <div className="col" key={project.id}>
      <ProjectCard {...project} />
    </div>
  ));
  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: isModalOpen ? 0 : "-100%" }}
      transition={{ delayChildren: 0.5 }}
      className="overlay"
    >
      <motion.div
        className="modal"
        id="modal"
        style={{ display: isModalOpen ? "block" : "none" }}
        initial={{ y: "-100vh" }}
        animate={{ y: isModalOpen ? "0" : "-100vh" }}
        transition={{ delay: 0.7 }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ff-hurricane fs-2">{t("modal-title")}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => toggleModal()}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row projects-row">{renderProjects}</div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => toggleModal()}
              >
                {t("close")}
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ProjectsModal;
