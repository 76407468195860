export const experiencesData = [
    {
      key: 1,
      firm: 'Linktera Bilgi Teknolojileri A.Ş.',
      position: 'Front-End Developer',
      startDate: 'start-date-1',
      endDate: 'end-date-1',
      projects: [
        {
          key: 1,
          name: 'HepsiPay',
          description:"experience-projects-desc-5",
          techStack: [
            'Typescript',
            'React',
            'JavaScript for SDK',
            'Scss',
            'React testing library',
            'Vite',
            'Zustand'
          ],
        },
        {
          key: 2,
          name: 'Hepsifinans',
          description:"experience-projects-desc-6",
          techStack: [
            'Typescript',
            'React',
            'Scss',
            'React testing library',
            'Vite',
            'Zustand',
            'ReactQuery'
          ],
        },
        {
          key: 3,
          name: 'Garanti Bank Renovation of Custody Web Branch',
          description:"experience-projects-desc-1",
          techStack: [
            'JavaScript',
            'LitElement',
            'WebComponents',
            'Karma - Chai - Mocha Unit Tests',
            'BitBucket',
          ],
        },
        {
          key: 4,
          name: 'İş Bank Affiliate Data Consolidation',
          description:"experience-projects-desc-2",
          techStack: [
            'JQuery',
            'JavaScript',
            'PugJs',
            'NodeJs',
            'ExpressJs',
            'GulpJs',
          ],
        },
        {
          key: 5,
          name: 'CreditBoost',
          description:"experience-projects-desc-3",
          techStack: [
            'JQuery',
            'JavaScript',
            'PugJs',
            'NodeJs',
            'ExpressJs',
            'GulpJs',
          ],
        },
        {
          key: 6,
          name: 'Yapı Kredi New Generation Leasing',
          description:"experience-projects-desc-4",
          techStack: ['ReactJs'],
        },
      ],
    },
  ];