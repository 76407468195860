import { useRef, useState } from "react";
import { t } from "i18next";
import "./projectLinkStyles.css";
import { getIcon } from "../../utils/svg-icons";
const ProjectLink = ({ link = "", description = "" }) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(link);
  const handleCopy = () => {
    const val = inputRef.current.value;
    
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setValue(t('copy'));
        setTimeout(() => {
          setValue(link);
        }, 1500);
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  return (
    <div className="project-item">
      <div className="project-description">{t(description)}</div>
      <div className="project-link">
        <input
          ref={inputRef}
          onClick={handleCopy}
          type="text"
          name="project-1"
          id="project-1"
          value={value}
          readOnly
          hidden
        />
        <input
          onClick={handleCopy}
          type="text"
          name="project-2"
          id="project-2"
          className="form-control"
          value={value !== link ? value : t('clone')}
          readOnly
        />
        
        <span className="copy-icon" onClick={handleCopy}>
          {getIcon("copy")}
        </span>
      </div>
    </div>
  );
};

export default ProjectLink;
