import { createContext, useState } from "react";
const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
  const deneme = "deneme";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <ModalContext.Provider value={{ deneme, isModalOpen, toggleModal }}>
      {children}
    </ModalContext.Provider>
  );
};
export { ModalContextProvider, ModalContext };
