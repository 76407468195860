import { useState } from "react";
import { t } from "i18next";
import { useToaster } from "../../hooks/useToaster";
import Spinner from "../spinner/Spinner";
import Error from "../error/Error";
import ThankYou from "../thankYou/ThankYou";
import "./contactStyles.css";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(null);

  const { toaster } = useToaster();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await fetch("https://personal-portfolio-nodejs.onrender.com/send-message", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name, email, message }),
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 201) {
          toaster("success", t("toaster-success-message"));
          clearForm();
          setIsSuccess(true);
        }
        if (response.status === 500) {
          setIsSuccess(false);
          toaster("error", t("toaster-error-message"));
        }
      })
      .catch((err) => {
        toaster("error", t("toaster-error-message"));
        setIsSuccess(false);
      });
  };

  const clearForm = () => {
    setMessage("");
    setEmail("");
    setName("");
  };
  return loading ? (
    <Spinner />
  ) : (
    <>
      {isSuccess !== null && isSuccess === true && <ThankYou />}
      {isSuccess !== null && isSuccess === false && <Error />}
      {isSuccess === null ? ( <form
        className='row g-3'
        onSubmit={handleFormSubmit}
      >
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            name="name"
            id="name"
            placeholder={t("name")}
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
        </div>
        <div className="col-md-6">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder={t("email")}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>
        <div className="col-12">
          <textarea
            className="form-control"
            name="message"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder={t("message")}
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            required
          ></textarea>
        </div>

        <div className="col-12 mt-4">
          <div className="d-grid gap-2 col-12 col-md-6">
            <button type="submit" className="p-2 btn btn-primary">
              {t("submit")}
            </button>
          </div>
        </div>
      </form>) : null }
     
    </>
  );
};

export default ContactForm;
