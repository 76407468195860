import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { links } from "../../utils/links";
import "./navbarStyles.css";
import i18next, { t } from "i18next";

const Navbar = ({type}) => {
  const renderNavlinks = links.map((link) => (
    <NavLink
      className={link.path === "/" ? "brand mr-auto ff-hurricane fs-5" : "nav-link"}
      key={link.id}
      to={link.path}
    >
      {t(link.label)}
    </NavLink>
  ));

  const [lang, setLang] = useState(localStorage.getItem("lang"));
  useEffect(() => {
    if (localStorage.getItem("lang", lang) !== null)
      i18next.changeLanguage(localStorage.getItem("lang", lang));
  }, [lang]);

  const toggleLang = (ev) => {
    setLang(ev.target.value);
    localStorage.setItem("lang", ev.target.value);
    i18next.changeLanguage(ev.target.value);
  };
  return (
    <div className={`navbar container-fluid p-2 ${type}`}>
      <div className="container">
        <div className="nav-links d-flex">
          <p className="footer-nav-title">
            {t('website-map')}
          </p>
          {renderNavlinks}
          <select
            className="lang-select"
            value={lang}
            onChange={(e) => toggleLang(e)}
          >
            <option value="en">EN</option>
            <option value="tr">TR</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
