import { useContext } from "react";
import Layout from "../layout/Layout";
import { motion } from "framer-motion";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { ModalContext } from "../context/modalContext";
import "./aboutStyles.css";
const About = () => {
  const { toggleModal } = useContext(ModalContext);
  return (
    <Layout>
      <motion.div
        initial={{ y: "-100vw" }}
        animate={{ y: 0 }}
        exit={{ y: "-100vw" }}
      >
        <div className="container py-2rem">
          
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <p className="text-normal">{t("about-par-1")}</p>
              <p className="text-normal">{t("about-par-2")}</p>
              <p className="text-normal">{t("about-par-3")}</p>
              <p className="text-normal">{t("about-par-4")}</p>
              <p className="text-normal">
              <Trans components={{ span: <span className='modal-link' onClick={() => toggleModal()}/> }}>projects-link</Trans>
               
              </p>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default About;
