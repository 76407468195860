import React from "react";
import { t } from "i18next";
const Spinner = () => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ minHeight: "300px" }}
    >
      <div className="spinner-grow" role="status">
        <span className="visually-hidden">{t("loading")}</span>
      </div>
    </div>
  );
};

export default Spinner;
