import React from "react";
import { Trans } from "react-i18next";
import { getIcon } from "../../utils/svg-icons";
import logo from "../../assets/logos/logo.svg";
import Navbar from "../navbar/Navbar";
import resume from "../../assets/pdf/resume.pdf";
import { t } from "i18next";
import "./footerStyles.css";
const Footer = () => {
  return (
    <div className="footer py-3">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="footer-left col-md-4">
              <Navbar type="footer-nav" />
            </div>
            <div className="footer-center col-md-4 d-flex align-items-end justify-content-center">
              <div className="bottom-links">
                {/* <div className="row">
                  <div className="col d-flex justify-content-center mt-4">
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/onuralp-ozkan/"
                      className="linkedin-bot mx-1"
                    >
                      {getIcon("linkedin")}
                    </a>
                    <a
                      target="_blank"
                      href="https://github.com/onuralpozkan"
                      className="github-bot mx-1"
                    >
                      {getIcon("github")}
                    </a>
                  </div>
                </div> */}
                <div className="row d-flex justify-content-center mt-3">
                  <p className="text-center text-normal footer-text">
                    <Trans
                      components={{
                        img: (
                          <img
                            src={logo}
                            width={32}
                            height={32}
                            className="App-logo"
                            alt="logo"
                          />
                        ),
                      }}
                    >
                      footer-info-text
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-right col-md-4 d-flex flex-column justify-content-center align-items-center">
              <p className="mt-4 text-center fs-1 ff-hurricane">
                Onuralp Özkan
              </p>
              <a
                href={resume}
                download="onuralp_ozkan_cv_2023"
                className="download-link"
              >
                <span className="download-icon flex-center">
                  {getIcon("download")}
                </span>
                <span className="download-label">{t("cv")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
