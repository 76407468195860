import WordCloudLeft from "./WordCloudLeft";
import WordCloudRight from "./WordCloudRight";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import "./bannerStyles.css";
const Banner = () => {
  const location = useLocation();
  const bannerTextData = [
    { path: "/", text: "home-title" },
    { path: "/about", text: "about-title" },
    { path: "/experiences", text: "experiences-title" },
    { path: "/contact", text: "contact-title" },
    { path: "/thank-you", text: "" },
    { path: "*", text: "notfound" },
  ];
  const bannerText = bannerTextData.filter(
    (item) => item.path === location.pathname
  )[0]?.text;
  return (
    <div className="container-fluid banner py-4">
      <div className="row d-flex">
        <div className="col-3 left-side">
          <WordCloudLeft />
        </div>
        <div className="col-6 center-side flex-center">
          <h1 className="ff-hurricane">{t(bannerText === undefined ? 'notfound' : bannerText)}</h1>
        </div>
        <div className="col-3 right-side">
          <WordCloudRight />
        </div>
      </div>
    </div>
  );
};

export default Banner;
