import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Welcome to React": "Welcome to React and react-i18next",
      "home-title":"Welcome!",
      "about-title":"About Me",
      "about-par-1":"Hi, I'm Onuralp Özkan. I have been working as a frontend developer for about two years and I have been involved in various projects.",
      "about-par-2":"My web design and development journey started about three and a half years ago. Since then, I continue to improve myself in this field and to learn new things constantly.",
      "about-par-3":"One of my earliest goal is to become an expert in JavaScript and develop full-stack applications using popular technologies, especially NodeJs and React.",
      "about-par-4":"For now, I can list the technologies and programs I use in development as follows: 'JavaScript, React, TypeScript, NodeJS, Express, HTML5, CSS3, Scss, Vite, Yarn, React testing library, JQuery, Pugjs, Git, GitHub, Figma, Inkscape, Gimp'",
      "about-projects-title":"Some of My Projects",
      "memorygame_app_desc":"A memory game created with React as a side project",
      "stopwatch_app_desc":"An application which has alarm, countdown and stopwatch functionalities",
      "analogclock_app_desc":"With different theme options, its a analog clock created with vanilla js and css",
      "testing_app_desc":"It is a React testing app that can possess various online tests such as English, French etc",
      "ecommerce_app_desc":"It is a e-commerce website example created wth React.js",
      "memorygame_app":"Memory Card Game",
      "stopwatch_app":"Stopwatch",
      "analogclock_app":"Analog Clock",
      "testing_app":"Testing App",
      "ecommerce_app":"E-Commerce",
      "copy":"Copied!",
      "contact-title":"Contact",
      "contact-via-link":"To reach me, you can send a mail via <a>this link</a> with your e-mail client or by submitting the form below.",
      "name":"Name*",
      "email":"E-Mail*",
      "message":"Write your message here...*",
      "submit":"Submit",
      "experiences-title":"Work Experiences",
      "experience-projects-title":"Projects",
      "experience-projects-subtitle-1":"Job Description",
      "experience-projects-subtitle-2":"Used Techs",
      "experience-projects-desc-1":"Tranforming old custody web app into the new structure which used component based Ark Framework responsible for ui dev.",
      "experience-projects-desc-2":"İş Bank affiliates data consolidation project, responsible for development features and bug fixes.",
      "experience-projects-desc-3":"I was responsible for frontend development of digital loan approval rate and process improvement application.",
      "experience-projects-desc-4":"Yapı Kredi Bank Leasing project which is using reactjs, responsible for giving support.",
      "experience-projects-desc-5":"I was responsible for frontend developments of a payment system for an e-commerce firm.",
      "experience-projects-desc-6":"I took part in the front-end developments of the financial structure created by the e-commerce company to provide loans to consumers.",
      "cv":"Download CV",
      "end-date-1":"Present",
      "start-date-1":"Fab 2021",
      "brand-link":"OÖzkan",
      "home-link":"Home",
      "contact-link":"Contact",
      "about-link":"About",
      "experience-link":"Experience",
      "project-link":"My Projects",
      "profile-welcome-text":"Welcome!",
      "footer-info-text":"© 2022 Created with React.js <img />, Bootstrap and Framer Motion",
      "clone":"Clone github link!",
      "modal-title":"My Projects",
      "thanks":"Thanks for your Interest!",
      "return":"Return to homepage",
      "projects-link":"<span>Click here!</span> for cloning some of my personal projects...",
      "close":"Close",
      "home-info-text":"I am a <span>frontend developer</span>, I am creating web apps with great technologies such as React, TypeScript, JavaScript, CSS, HTML so on.",
      "location": "Kastamonu, TURKEY",
      "greeting": "Hello,",
      "my-name": "I'm Onuralp Özkan",
      "say-hello":"Say Hello",
      "game-link": "Memory Game",
      "website-map":"Website Navigation",
      "not-found":"Check the url or return home page",
      "notfound":"404 - Not Found",
      "toaster-success-message": "Your message has been sent successfully!",
      "toaster-error-message": "Your message could not be sent!",
      "error-message": "There is an error occured on server. Please try again later..."
    },
  },
  tr: {
    translation: {
      "Welcome to React": "Reacta hoş geldin",
      "home-title":"Hoş Geldiniz!",
      "about-title":"Hakkımda",
      "about-par-1":"Merhaba, ben Onuralp Özkan. Yaklaşık iki yıldır önyüz geliştricisi olarak çalışmakta ve çeşitli projelerde yer almaktayım.",
      "about-par-2":"Web tasarım ve geliştirme serüvenim yaklaşık üç buçuk yıl önce başladı. O günden bugüne bu alanda kendimi geliştirmeye ve sürekli yeni şeyler öğrenmeye devam ediyorum.",
      "about-par-3":"İlk aşamada hedefim JavaScript konusunda uzmanlaşmak NodeJs ve React başta olmak üzere popüler teknolojileri kullanarak tam kapsamlı uygulamalar geliştirmek diyebilirim.",
      "about-par-4":"Şu an için geliştirmelerde kullandığım teknolojileri ve programları şu şekilde sayabilirim: 'JavaScript, React, TypeScript, NodeJS, Express, HTML5, CSS3, Scss, Vite, Yarn, React testing library, JQuery, Pugjs, Git, GitHub, Figma, Inkscape, Gimp'",
      "about-projects-title":"Bazı Projelerim",
      "memorygame_app_desc":"React ile geliştirilen bir hafıza kart oyunu.",
      "stopwatch_app_desc":"Alarm, geri sayım ve kronometre özelliklerine sahip bir uygulama",
      "analogclock_app_desc":"Farklı temalara sahip sadece JavaScript ve CSS kullanılarak yapılmış bir uygulama",
      "testing_app_desc":"Çeşitli testleri kendinde barındırabilen React ile yapılmış bir test uygulaması",
      "ecommerce_app_desc":"React ile yapılmış bir E-Ticaret web sitesi örneği",
      "memorygame_app":"Hafıza Kart Oyunu",
      "stopwatch_app":"Kronometre",
      "analogclock_app":"Analog Saat",
      "testing_app":"Test Uygulaması",
      "ecommerce_app":"E-Ticaret",
      "copy":"Kopyalandı!",
      "contact-title":"Bana Ulaş",
      "contact-via-link":"Kendi e-posta istemciniz ile bana ulaşmak için <a className='email-link' href='mailto:onuralpozkan01@gmail.com'>bu linke tıklayabilir</a> ya da aşağıdaki formu doldurabilirsiniz.",
      "name":"İsim*",
      "email":"E-Posta*",
      "message":"Mesajınızı buraya yazabilirsiniz...*",
      "submit":"Gönder",
      "experiences-title":"İş Tecrübelerim",
      "experience-projects-title":"Projeler",
      "experience-projects-subtitle-1":"İş Tanımı",
      "experience-projects-subtitle-2":"Kullandığım Teknolojiler",
      "experience-projects-desc-1":"Garanti Bankası Saklama internet şubesinin yeniden yapılmasında baştan sona önyüz geliştirmesinden sorumluydum.",
      "experience-projects-desc-2":"İş Bankası İştirak Verileri Konsolidasyon Projesinin yapımında özellik geliştirme ve hata çözümlerinden sorumluydum.",
      "experience-projects-desc-3":"Dijital kredi onay oranı ve süreç iyileştirme uygulamasının önyüz geliştirmesinden sorumluydum.",
      "experience-projects-desc-4":"Yapı Kredi Bankası Leasing Projesi için React.js konusunda danışmanlık ve destek verdim.",
      "experience-projects-desc-5":"E-ticaret firması için bir ödeme sisteminin önyüz geliştirmelerinden sorumluydum.",
      "experience-projects-desc-6":"E-ticaret firmasının tüketicilere kredi verebilmek için oluşutruduğu finansal yapının önyüz geliştirmelerindegörev aldım.",
      "cv":"CV İndir",
      "end-date-1":"Devam Ediyor",
      "start-date-1":"Şubat 2021",
      "brand-link":"OÖzkan",
      "home-link":"Anasayfa",
      "contact-link":"Bana Ulaş",
      "about-link":"Hakkımda",
      "experience-link":"Tecrübe",
      "project-link":"Projelerim",
      "profile-welcome-text":"Hoş Geldiniz!",
      "footer-info-text":"© 2023 React.js <img />, Bootstrap ve Framer Motion ile yapılmıştır",
      "clone":"Github linkini kopyala!",
      "modal-title":"Projelerim",
      "thanks":"İlginiz için Teşekkürler!",
      "return": 'Anasayfaya geri dön',
      "projects-link":"Bazı şahsi projelerimi klonlamak için <span>buraya tıklayınız...</span>",
      "close":"Kapat",
      "home-info-text":"Ben bir <span>önyüz gelştiricisiyim</span>, React, TypeScript, JavaScipt, HTML, CSS gibi harika teknolojiler kullanarak web uygulamaları geliştiriyorum.",
      "location": "Kastamonu, TÜRKİYE",
      "greeting": "Merhaba,",
      "my-name":"Ben Onuralp Özkan",
      "say-hello":"Merhaba Diyebilirsin",
      "game-link": "Hafıza Oyunu",
      "website-map":"Site Haritası",
      "not-found":"URL'i kontrol edebilir veya anasayfaya dönebilirsin!",
      "notfound":"404 - Bulunamadı!",
      "toaster-success-message": "Mesajınız başarıyla gönderildi.",
      "toaster-error-message": "Mesajınız gönderilemedi!",
      "error-message": "Serverda bir hata meydana geldi. Lütfen daha sonra tekrar deneyiniz..."
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
