export const getIcon = (icon) => {
  switch (icon) {
    case "experience":
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="60px"
          viewBox="0 0 256 203"
          style={{ enableBackground: "new 0 0 256 203" }}
          xmlSpace="preserve"
          fill="var(--lighttheme-primary-color)"
        >
          <path
            d="M84.2,2.3c18.7,0,33.8,15.1,33.8,33.8s-15.1,33.8-33.8,33.8S50.4,54.7,50.4,36.1S65.5,2.3,84.2,2.3z M188.8,151.4
       c4.3,0,7.8,2.2,7.8,5s-3.5,5-7.8,5c-4.3,0-7.8-2.2-7.8-5S184.5,151.4,188.8,151.4z M149.3,112.1l-3.9,11.1c-4-8.3-8-16.3-11.1-21.8
       c-3.4-7.2-8.7-13.3-15.3-17.6c-6.6-4.3-14.5-6.8-22.9-6.8H44.2C20.9,76.9,2,96,2,119.1V183c0,9.8,7.9,17.7,17.7,17.7h202.7
       l31.6-88.6H149.3z M215.5,190.9H88.6v-5.7c0-8.8-7.2-16-16-16H33.5v-43.3c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9v35.4h31.2
       c12.4,0,22.6,9.5,23.8,21.7h38.1l21.7-61H240L215.5,190.9z"
          />
        </svg>
      );
    case "projects":
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="60px"
          viewBox="0 0 256 203"
          style={{ enableBackground: "new 0 0 256 203" }}
          xmlSpace="preserve"
          fill="var(--lighttheme-primary-color)"
        >
          <path
            d="M84.2,2.3c18.7,0,33.8,15.1,33.8,33.8s-15.1,33.8-33.8,33.8S50.4,54.7,50.4,36.1S65.5,2.3,84.2,2.3z M188.8,151.4
       c4.3,0,7.8,2.2,7.8,5s-3.5,5-7.8,5c-4.3,0-7.8-2.2-7.8-5S184.5,151.4,188.8,151.4z M149.3,112.1l-3.9,11.1c-4-8.3-8-16.3-11.1-21.8
       c-3.4-7.2-8.7-13.3-15.3-17.6c-6.6-4.3-14.5-6.8-22.9-6.8H44.2C20.9,76.9,2,96,2,119.1V183c0,9.8,7.9,17.7,17.7,17.7h202.7
       l31.6-88.6H149.3z M215.5,190.9H88.6v-5.7c0-8.8-7.2-16-16-16H33.5v-43.3c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9v35.4h31.2
       c12.4,0,22.6,9.5,23.8,21.7h38.1l21.7-61H240L215.5,190.9z"
          />
        </svg>
      );
    case "about":
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="60px"
          viewBox="0 0 256 203"
          style={{ enableBackground: "new 0 0 256 203" }}
          xmlSpace="preserve"
          fill="var(--lighttheme-primary-color)"
        >
          <path
            d="M84.2,2.3c18.7,0,33.8,15.1,33.8,33.8s-15.1,33.8-33.8,33.8S50.4,54.7,50.4,36.1S65.5,2.3,84.2,2.3z M188.8,151.4
         c4.3,0,7.8,2.2,7.8,5s-3.5,5-7.8,5c-4.3,0-7.8-2.2-7.8-5S184.5,151.4,188.8,151.4z M149.3,112.1l-3.9,11.1c-4-8.3-8-16.3-11.1-21.8
         c-3.4-7.2-8.7-13.3-15.3-17.6c-6.6-4.3-14.5-6.8-22.9-6.8H44.2C20.9,76.9,2,96,2,119.1V183c0,9.8,7.9,17.7,17.7,17.7h202.7
         l31.6-88.6H149.3z M215.5,190.9H88.6v-5.7c0-8.8-7.2-16-16-16H33.5v-43.3c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9v35.4h31.2
         c12.4,0,22.6,9.5,23.8,21.7h38.1l21.7-61H240L215.5,190.9z"
          />
        </svg>
      );
    case "github":
      return (
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
          id="deneme"
        >
          <path
            clipRule="evenodd"
            d="M12 0C5.37 0 0 5.37 0 12C0 17.31 3.435 21.795 8.205 23.385C8.805 23.49 9.03 23.13 9.03 22.815C9.03 22.53 9.015 21.585 9.015 20.58C6 21.135 5.22 19.845 4.98 19.17C4.845 18.825 4.26 17.76 3.75 17.475C3.33 17.25 2.73 16.695 3.735 16.68C4.68 16.665 5.355 17.55 5.58 17.91C6.66 19.725 8.385 19.215 9.075 18.9C9.18 18.12 9.495 17.595 9.84 17.295C7.17 16.995 4.38 15.96 4.38 11.37C4.38 10.065 4.845 8.985 5.61 8.145C5.49 7.845 5.07 6.615 5.73 4.965C5.73 4.965 6.735 4.65 9.03 6.195C9.99 5.925 11.01 5.79 12.03 5.79C13.05 5.79 14.07 5.925 15.03 6.195C17.325 4.635 18.33 4.965 18.33 4.965C18.99 6.615 18.57 7.845 18.45 8.145C19.215 8.985 19.68 10.05 19.68 11.37C19.68 15.975 16.875 16.995 14.205 17.295C14.64 17.67 15.015 18.39 15.015 19.515C15.015 21.12 15 22.41 15 22.815C15 23.13 15.225 23.505 15.825 23.385C18.2072 22.5808 20.2773 21.0498 21.7438 19.0074C23.2103 16.9651 23.9994 14.5143 24 12C24 5.37 18.63 0 12 0Z"
            fill="var(--darktheme-light-color)"
          />
        </svg>
      );
    case "linkedin":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#000"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.3333 0H2.66667C1.19333 0 0 1.19333 0 2.66667V21.3333C0 22.8067 1.19333 24 2.66667 24H21.3333C22.8067 24 24 22.8067 24 21.3333V2.66667C24 1.19333 22.8067 0 21.3333 0ZM8 18.6667H4.636V9.33333H8V18.6667ZM6.25867 7.62267C5.23067 7.62267 4.544 6.93733 4.544 6.02267C4.544 5.108 5.22933 4.42267 6.372 4.42267C7.4 4.42267 8.08667 5.108 8.08667 6.02267C8.08667 6.93733 7.40133 7.62267 6.25867 7.62267ZM20 18.6667H16.744V13.5653C16.744 12.1547 15.876 11.8293 15.5507 11.8293C15.2253 11.8293 14.14 12.0467 14.14 13.5653C14.14 13.7827 14.14 18.6667 14.14 18.6667H10.776V9.33333H14.14V10.636C14.5733 9.876 15.4413 9.33333 17.0693 9.33333C18.6973 9.33333 20 10.636 20 13.5653V18.6667Z"
            fill="var(--darktheme-light-color)"
          />
        </svg>
      );
    case "download":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z"
            fill="#F9F7F7"
          />
        </svg>
      );
    case "copy":
      return (
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"
            fill="var(--darktheme-light-color)"
          />
        </svg>
      );
    default:
      return (
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="60px"
          viewBox="0 0 256 203"
          style={{ enableBackground: "new 0 0 256 203" }}
          xmlSpace="preserve"
          fill="var(--lighttheme-primary-color)"
        >
          <path
            d="M84.2,2.3c18.7,0,33.8,15.1,33.8,33.8s-15.1,33.8-33.8,33.8S50.4,54.7,50.4,36.1S65.5,2.3,84.2,2.3z M188.8,151.4
       c4.3,0,7.8,2.2,7.8,5s-3.5,5-7.8,5c-4.3,0-7.8-2.2-7.8-5S184.5,151.4,188.8,151.4z M149.3,112.1l-3.9,11.1c-4-8.3-8-16.3-11.1-21.8
       c-3.4-7.2-8.7-13.3-15.3-17.6c-6.6-4.3-14.5-6.8-22.9-6.8H44.2C20.9,76.9,2,96,2,119.1V183c0,9.8,7.9,17.7,17.7,17.7h202.7
       l31.6-88.6H149.3z M215.5,190.9H88.6v-5.7c0-8.8-7.2-16-16-16H33.5v-43.3c0-2.2,1.8-3.9,3.9-3.9s3.9,1.8,3.9,3.9v35.4h31.2
       c12.4,0,22.6,9.5,23.8,21.7h38.1l21.7-61H240L215.5,190.9z"
          />
        </svg>
      );
  }
};
