export const links = [
  {
    id: 0,
    path: "/",
    label: "brand-link",
  },
  {
    id: 1,
    path: "/experiences",
    label: "experience-link",
  },
  {
    id: 2,
    path: "/about",
    label: "about-link",
  },
  {
    id: 3,
    path: "/contact",
    label: "contact-link",
  },
];

export const githubLinks = [
  {
    id: 1,
    title: "memorygame_app",
    imgSrc: "/images/memory_card_game.png",
    desc: "memorygame_app_desc",
    link: "https://github.com/onuralpozkan/memory-card-game.git",
  },
  {
    id: 2,
    title: "stopwatch_app",
    imgSrc: "/images/stopwatch_app.png",
    desc: "stopwatch_app_desc",
    link: "https://github.com/onuralpozkan/stopwatch.git",
  },
  {
    id: 3,
    title: "analogclock_app",
    imgSrc: "/images/analogclock_app.png",
    desc: "analogclock_app_desc",
    link: "https://github.com/onuralpozkan/analog-clock.git",
  },
  {
    id: 4,
    title: "testing_app",
    imgSrc: "/images/testing_app.png",
    desc: "testing_app_desc",
    link: "https://github.com/onuralpozkan/test_app.git",
  },
  {
    id: 5,
    title: "ecommerce_app",
    imgSrc: "/images/ecommerce_app.png",
    desc: "ecommerce_app_desc",
    link: "https://github.com/onuralpozkan/react-ecommerce.git",
  },
];
